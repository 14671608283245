import Auth from './Auth'

class ApiClient {
  static API_BASE_URL = process.env.REACT_APP_API
  static JSON_CONTENT_TYPE = 'application/json'

  request(url, method = 'GET', body = null) {
    const payload = this.preparePayload(body)
    const headers = this.prepareHeaders()

    return fetch(`${ApiClient.API_BASE_URL}${url}`, {
      method,
      headers,
      body: payload,
    }).then(this.handleResponse)
  }

  preparePayload(body) {
    if (typeof body === 'string' || body === null || body instanceof FormData) {
      return body
    }
    return JSON.stringify(body)
  }

  prepareHeaders() {
    return {
      // Accept: ApiClient.JSON_CONTENT_TYPE,
      Authorization: `Bearer ${Auth.getJWT()}`,
      'Content-Type': 'application/json',
      Accept: 'application/json, text-plain, */*',
      'X-Requested-With': 'XMLHttpRequest',
    }
  }

  handleResponse(response) {
    if (response.headers.get('content-type') === ApiClient.JSON_CONTENT_TYPE) {
      switch (response.status) {
        case 200:
        case 201:
          return response.json()
        case 401:
          window.location.href = '/login'
          const error = new Error('You must be logged in')
          return Promise.reject(error)
      }
    }
    const error = new Error('Unexpected content type')
    return Promise.reject(error)
  }
}

const apiClient = new ApiClient()

window.apiClient = apiClient

export default apiClient
