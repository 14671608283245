import React, { useEffect, useState } from 'react'
import CardHeader from '../../components/card/CardHeader'
import Card from '../../components/card/Card'
import Spacer from '../../components/Spacer'
import ProductCard from '../../components/ProductCard'
import Customer from '../../Customer'
import Button from '../../components/Button'
import InfoCard from '../../components/InfoCard'
import coinSound from '../../assets/sounds/coin.wav'
import errorSound from '../../assets/sounds/meepmerp.wav'
import SelectedProduct from '../../SelectedProduct'
import { useHistory } from 'react-router'
import ApiClient from '../../ApiClient'
import LoadingCard from '../../components/LoadingCard'

const EMPTY_CUSTOMER = { name: '', transactionInfo: { today: 0, total: 0 } }

const cache = {
  customer: EMPTY_CUSTOMER,
}

const fetchCustomer = async () => {
  cache.customer = await ApiClient.request('customers/' + Customer.getId())
  return cache.customer
}

const Dashboard = () => {
  const [message, setMessage] = useState('')
  const [product, setProduct] = useState('')
  const [customer, setCustomer] = useState(cache.customer)
  const [lastPurchase, setLastPurchase] = useState('')
  const history = useHistory()

  const coin = new Audio(coinSound)
  const error = new Audio(errorSound)

  const addOne = () => {
    if (typeof product === 'undefined' || typeof product.name === 'undefined') {
      setMessage('Selecteer een product 😉')
      error.play().then()
    } else {
      createTransaction().then(setMessage(`1 ${product.name} gekocht! 🧃`))
      coin.play().then()
    }
    setTimeout(() => setMessage(''), 5000)
  }

  const createTransaction = () =>
    ApiClient.request('transactions', 'POST', {
      customer: customer.id,
      product: product.id,
    }).then((result) => setLastPurchase(result))

  const selectOtherUser = () => {
    Customer.delId()
    history.push('/select')
  }

  const minimumIncome = () => {
    if (customer.balance < 0) return Math.abs(customer.balance) + 10
    return 10
  }

  useEffect(() => {
    ApiClient.request('products/' + SelectedProduct.get())
      .then(setProduct)
      .catch(() => SelectedProduct.pop())
  }, [])

  useEffect(() => {
    fetchCustomer().then(setCustomer)
  }, [lastPurchase])

  if (customer === EMPTY_CUSTOMER)
    return (
      <Card>
        <LoadingCard first="De gebruiker wordt geladen" second="Een momentje" />
      </Card>
    )

  return (
    <Card transparent>
      <CardHeader round message={message} onMessageClose={() => setMessage('')}>
        Hallo, {customer.name}
      </CardHeader>
      <div className="column">
        <div onClick={() => history.push('/products')}>
          {SelectedProduct.get() ? (
            product ? (
              <ProductCard {...product} />
            ) : (
              <LoadingCard first="Nieuw product inladen" second="Een momentje" />
            )
          ) : (
            <InfoCard first="Er is nog geen product geselecteerd" second="Selecteer een product" />
          )}
        </div>
        <div className="row">
          <a
            className="block"
            href={`https://bunq.me/stampot/${minimumIncome()}/Opwaarderen voor ${customer.name}`}
            target="_blank"
            rel="noreferrer"
          >
            <InfoCard first="Saldo" second={'€ ' + (customer.balance?.toFixed(2) || 0)} third="Opwarderen" />
          </a>
          <Button style={{ height: '75px' }} onClick={addOne}>
            +1
          </Button>
        </div>
        <div className="row">
          <div className="block" onClick={() => history.push('/log/' + customer.id)}>
            <InfoCard first="Vandaag" second={customer.transactionInfo.today} third="Gedronken" />
          </div>
          <div className="block" onClick={() => history.push('/log')}>
            <InfoCard first="Totaal" second={customer.transactionInfo.total} third="Gedronken" />
          </div>
        </div>
        <Spacer />
        <Button block onClick={selectOtherUser}>
          Andere gebruiker
        </Button>
      </div>
    </Card>
  )
}

export default Dashboard
