import React from 'react'
import Card from '../components/card/Card'
import CardHeader from '../components/card/CardHeader'
import CardBody from '../components/card/CardBody'
import CardButtons from '../components/card/CardButtons'
import Button from '../components/Button'
import Spacer from '../components/Spacer'
import Auth from '../Auth'
import Input from '../components/Input'
import errorSound from '../assets/sounds/meepmerp.wav'

class VoterMainPage extends React.Component {
  error = new Audio(errorSound)
  state = {
    password: '',
    passwordValid: false,
    errorMessage: '',
  }

  handleMessageClose = () => this.setState({ errorMessage: '' })

  render() {
    return (
      <>
        <Spacer />
        <Card>
          <CardHeader message={this.state.errorMessage} onMessageClose={this.handleMessageClose}>
            Let me in!
          </CardHeader>
          <CardBody>
            <form onSubmit={this.getJWT}>
              <Input
                placeholder="Wachtwoord"
                type="password"
                value={this.state.password}
                onChange={this.handleChange}
              />
              <CardButtons>
                <Button className="block" disabled={!this.state.passwordValid}>
                  Inloggen
                </Button>
              </CardButtons>
            </form>
          </CardBody>
        </Card>
        <Spacer size={2} />
      </>
    )
  }

  handleChange = (event) =>
    this.setState({
      password: event.currentTarget.value,
      passwordValid: event.currentTarget.validity.valid,
    })

  getJWT = (e) => {
    e.preventDefault()
    // Clear error message before sending of another request
    this.setState({ errorMessage: '' })
    fetch(`${process.env.REACT_APP_API}auth/login`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: 'customer',
        password: this.state.password,
      }),
    })
      .then((result) => (result.ok ? result.json() : Promise.reject('Verkeerd wachtwoord')))
      .then((result) => this.login(result))
      .catch((errorMessage) => {
        this.error.play().then()
        this.setState({ errorMessage })
      })
  }

  login = (jwt) => {
    console.log(jwt)
    Auth.authenticate(jwt)
    this.props.history.push('/select')
  }
}

export default VoterMainPage
