import React, { useEffect } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import './styles/app.scss'
import Header from './components/Header'
import PageNotFound from './pages/customer/PageNotFound'
import CustomerSelector from './pages/customer/CustomerSelector'
import PrivateRoute from './components/PrivateRoute'
import Dashboard from './pages/customer/Dashboard'
import Products from './pages/customer/Products'
import Spacer from './components/Spacer'
import Log from './pages/customer/Log'
import Login from './pages/Login'

const App = () => {
  useEffect(() => {
    drunkCheck()
  }, [])

  const drunkCheck = () => {
    const now = new Date()
    const hours = now.getHours()
    if (hours >= 2 && hours < 6) {
      document.body.classList.add('drunk')
    }
  }

  return (
    <BrowserRouter>
      <div id="app">
        <Header />
        <Spacer />
        <Switch>
          <Route path="/login" exact component={Login} />
          <PrivateRoute path="/select" exact component={CustomerSelector} />
          <PrivateRoute path="/products" exact component={Products} />
          <PrivateRoute path="/log/:customer" exact component={Log} />
          <PrivateRoute path="/log" exact component={Log} />
          <PrivateRoute path="/" exact component={Dashboard} />
          <Route component={PageNotFound} status={404} />
        </Switch>
        <Spacer size={2} />
      </div>
    </BrowserRouter>
  )
}

export default App
