import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Card from '../../components/card/Card'
import CardHeader from '../../components/card/CardHeader'
import CardBody from '../../components/card/CardBody'
import CardButtons from '../../components/card/CardButtons'
import Button from '../../components/Button'
import Customer from '../../Customer'
import Select from 'react-select'
import ApiClient from '../../ApiClient'
import LoadingCard from '../../components/LoadingCard'

const cache = {
  customers: [],
}

const fetchCustomers = async () => {
  cache.customers = await ApiClient.request('customers')
  return ApiClient.request('customers')
}

const customSelectStyles = {
  control: (styles, { isFocused }) => ({
    ...styles,
    border: isFocused ? '#800080' : '#ffffff',
    boxShadow: '0 4px 4px rgba(0,0,0,0.25)',
    backgroundColor: 'white',
  }),
  menu: (provided, state) => ({
    ...provided,
  }),
  option: (styles, { isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isFocused ? '#93a8ac' : isSelected ? '#08605f' : '#ffffff',
      color: isSelected ? '#ffffff' : '#000000',
      cursor: isDisabled ? 'not-allowed' : 'default',

      ':active': {
        ...styles[':active'],
        backgroundColor: '#93a8ac',
      },
    }
  },
}

const CustomerSelector = () => {
  const [customer, setCustomer] = useState('')
  const [customerValid, setCustomerValid] = useState(false)
  const [customers, setCustomers] = useState(cache.customers)
  const history = useHistory()

  useEffect(() => {
    fetchCustomers().then(setCustomers)
  }, [])

  const handleChange = ({ value }) => {
    setCustomer(value)
    setCustomerValid(true)
  }

  const selectCustomer = (e) => {
    e.preventDefault()
    Customer.setId(customer)
    history.push('/')
  }

  if (customers.length === 0)
    return (
      <Card>
        <LoadingCard first="Gebruikers inladen" second="Een momentje" />
      </Card>
    )
  return (
    <Card>
      <CardHeader>Wie ben je?</CardHeader>
      <CardBody>
        <form onSubmit={selectCustomer}>
          <Select
            onChange={handleChange}
            options={customers.map((customer) => ({ value: customer.id, label: customer.name }))}
            styles={customSelectStyles}
          />
          <CardButtons>
            <Button block disabled={!customerValid}>
              Verder
            </Button>
          </CardButtons>
        </form>
      </CardBody>
    </Card>
  )
}

export default CustomerSelector
