import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import Customer from '../Customer'
import Auth from '../Auth'

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      if (!Auth.isAuthenticated()) {
        return <Redirect to="/login" />
      }
      if (rest['path'] !== '/select' && !Customer.isSet()) {
        return <Redirect to="/select" />
      }
      return <Component {...props} />
    }}
  />
)

export default PrivateRoute
