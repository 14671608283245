import React, { useEffect, useState } from 'react'
import CardHeader from '../../components/card/CardHeader'
import Card from '../../components/card/Card'
import ProductCard from '../../components/ProductCard'
import SelectedProduct from '../../SelectedProduct'
import { useHistory } from 'react-router'
import ApiClient from '../../ApiClient'
import InfoCard from '../../components/InfoCard'
import LoadingCard from '../../components/LoadingCard'

const cache = {
  products: [],
}

const fetchProducts = async () => {
  cache.products = await ApiClient.request('products')
  return cache.products
}

const Products = () => {
  const [products, setProducts] = useState(cache.products)
  const history = useHistory()

  const selectProduct = (product) => {
    SelectedProduct.set(product.id)
    history.push('/')
  }

  useEffect(() => {
    fetchProducts().then(setProducts)
  }, [])

  if (products.length === 0)
    return (
      <Card>
        <LoadingCard first="Producten inladen" second="Een momentje" />
      </Card>
    )

  return (
    <Card transparent>
      <CardHeader round>Producten</CardHeader>
      <div className="column">
        {products.map((product) => (
          <div onClick={() => selectProduct(product)}>
            <ProductCard key={product.id} {...product} />
          </div>
        ))}
      </div>
    </Card>
  )
}

export default Products
