import React from 'react'
import Card from './card/Card'

const LoadingCard = ({ first, second, third }) => {
  return (
    <Card>
      <div className="loading-card__container">
        <div className="loading-card__image" />
        <span className="loading-card__description">{first}</span>
        <span className="loading-card__name">{second}</span>
        <span className="loading-card__price">{third}</span>
      </div>
    </Card>
  )
}

export default LoadingCard
