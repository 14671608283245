import { jwtDecode } from 'jwt-decode'

const Auth = {
  getJWT: () => localStorage.getItem('jwt'),
  isAuthenticated: () => Auth.getJWT() !== null,
  getRole: () => (Auth.getJWT() ? jwtDecode(Auth.getJWT()).sub['type'] : false),
  isAuthorized: (type) => Auth.getRole() === type,
  authenticate: (jwt) => localStorage.setItem('jwt', jwt),
  signout: () => localStorage.removeItem('jwt'),
}

export default Auth
